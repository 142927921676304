<template>
  <el-card class="book-version">
    <div class="body">
      <el-form :model="data" label-width="120px" class="form" size="mini">
        <el-form-item label="导出类型">
          <el-select v-model="data.output_cfg.type">
            <el-option label="方正" value="founder">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="导出版本">
          <el-select v-model="data.output_cfg.version">
            <el-option v-for="item in founderVersionOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>

<script>
export default {
  components: {
  },
  props: {
    styleData: {
      type: Object,
      required: true
    }
  },
  mounted () {
  },
  created () {
    if (this.styleData.components[0].output_cfg) {
      this.data = this.styleData.components[0];
    } else {
      this.$set(this.styleData.components[0], 'output_cfg', this.data.output_cfg);
    }
  },
  data () {
    return {
      data: {
        output_cfg: {
          type: '',
          version: '',
        }
      },
      founderVersionOptions: [{
        label: '10.0',
        value: '10.0'
      }, {
        label: '11.0',
        value: '11.0'
      }]
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
</style>
